import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import Lottie from 'react-lottie';

import confetti from 'Lotties/confetti_desktop.json';
import confettiMobile from 'Lotties/confetti_mobile.json';

import { DefaultContext } from 'Context/DefaultContext';
import { StateContext } from 'context/StateContext';

import useWindowSize from 'Hooks/useWindowSize';

import * as v from 'Utils/variables';
import colors from 'Utils/theme';

import HeaderComponent from 'Components/header';
import Link from 'Components/link';

import Chevron from 'Icons/Chevron';
import Pin from 'Icons/LocationPin';

const DrawerLink = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-align: left;

  width: 100%;

  svg {
    margin-left: 7px;
    margin-top: 3px;
    flex: 0 0 10px;
    width: 10px;
    height: 10px;
  }

  @media (min-width: ${v.minMobile}) {
    justify-content: flex-start;
    width: auto;
  }
`;

const PinButton = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  margin: 0;

  svg {
    width: 18px;
    height: auto;
  }
`;

const ConfettiWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 665;
`;

const Header = ({ style = null }) => {
  const { isAuthenticated, keys, walmartPlusCheckoutURL } = useContext(DefaultContext);
  const { menuState, userState, addressesState } = useContext(StateContext);

  const { inverted } = menuState;
  const { hasInHome } = userState;
  const { addresses, storedAddress } = addressesState;

  const { windowWidth } = useWindowSize();

  const router = useRouter();

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [lastFocusedEl, setLastFocusedEl] = useState(null);

  const toggleDrawer = () => {
    if (isDrawerOpen) {
      lastFocusedEl && lastFocusedEl.focus();
    } else {
      setLastFocusedEl(document.activeElement);
    }
    setDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    if (isDrawerOpen && keys && keys.includes(27)) {
      lastFocusedEl && lastFocusedEl.focus();
      setDrawerOpen(!isDrawerOpen);
    }

    return () => setDrawerOpen(false);
  }, [keys]);

  const [showAnimation, setShowAnimation] = useState(false);
  useEffect(() => {
    if (showAnimation) {
      const timer = setTimeout(() => {
        setShowAnimation(false);
      }, [3500]);

      return () => clearTimeout(timer);
    }
  }, [showAnimation]);

  const eligibleAccountAddress = isAuthenticated && storedAddress !== undefined && !storedAddress && addresses?.find((address) => address.eligible === true);

  const isEligible = storedAddress !== undefined && !storedAddress && eligibleAccountAddress ? true : storedAddress ? storedAddress.eligible : false;

  const drawerAddress = isEligible && (eligibleAccountAddress ? eligibleAccountAddress.addressLineOne : storedAddress.addressLineOne ? storedAddress.addressLineOne : storedAddress.postalCode);

  const path = router.pathname;

  const useMinifiedMenu = path === '/get-started' || path === '/scheduling' || path === '/confirm';

  useEffect(() => {
    isEligible && router.pathname === '/get-started' && setShowAnimation(true);
  }, [isEligible]);

  const getButtons = () => {
    if (!isAuthenticated) {
      return [
        {
          name: router.pathname === '/get-started' ? (isEligible ? 'Get Started' : 'See if we deliver to you') : 'Start 30-day free trial',
          href: router.pathname === '/get-started' ? (isEligible ? '/checkout' : null) : walmartPlusCheckoutURL,
          track: true,
          trackId: 'add_to_cart',
          onClick: () => router.pathname === '/get-started' && !isEligible && document?.getElementById('autoCompleteInput')?.focus(),
        },
      ];
    } else {
      if (!hasInHome) {
        return [
          {
            name: router.pathname === '/get-started' ? (isEligible ? 'Get Started' : 'See if we deliver to you') : 'Start 30-day free trial',
            href: router.pathname === '/get-started' ? (isEligible ? '/checkout' : null) : walmartPlusCheckoutURL,
            track: true,
            trackId: 'add_to_cart',
            onClick: () => router.pathname === '/get-started' && !isEligible && document?.getElementById('autoCompleteInput')?.focus(),
          },
        ];
      }
      return null;
    }
  };

  return (
    <>
      {showAnimation && (
        <ConfettiWrapper>
          <Lottie
            options={{
              animationData: windowWidth < 720 ? confettiMobile : confetti,
              autoplay: true,
              loop: false,
              rendererSettings: {
                preserveAspectRatio: 'xMinYMin meet',
              },
            }}
          />
        </ConfettiWrapper>
      )}
      <HeaderComponent
        style={style}
        hasInHome={hasInHome}
        eligibilityComponent={
          isEligible ? (
            <>
              We deliver to {drawerAddress}! <Link href={walmartPlusCheckoutURL}>Sign up</Link> for a free month now
            </>
          ) : (
            <>
              <DrawerLink role="button" title="Eligible for InHome Delivery" tabIndex="0" ariaLabel="See if you're eligible for InHome Delivery" href="#" onClick={(e) => e.preventDefault() + toggleDrawer()}>
                For a limited time, InHome is offering Doorstep Delivery with deferred installation <Chevron fill={colors.white} />
                {/* TODO: bring back after covid-19 emergency */}
                {/* See if you're eligible for InHome Delivery <Chevron fill={colors.white} /> */}
              </DrawerLink>
            </>
          )
        }
        handleDrawer={toggleDrawer}
        buttons={getButtons()}
        isEligible={isEligible}
        useMinifiedMenu={useMinifiedMenu}
        isAuthenticated={isAuthenticated}
        pinButton={
          <PinButton role="button" title="Eligible for InHome Delivery" tabIndex="0" ariaLabel="See if you're eligible for InHome Delivery" href="#" onClick={(e) => e.preventDefault() + toggleDrawer()}>
            <Pin fill={inverted ? colors.white : colors.walmart.blue} />
          </PinButton>
        }
        leftMenu={<></>}
        rightMenu={<>{!useMinifiedMenu && <Link href="/fyi">Frequently Asked Questions</Link>}</>}
      />
    </>
  );
};

export default Header;
